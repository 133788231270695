<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar v-model="snackbar" :timeout="-1" bottom color="error">
      <ul>
        <li v-if="response_errors.errors.first_name">{{ response_errors.errors.first_name }}</li>
        <li v-if="response_errors.errors.last_name">{{ response_errors.errors.last_name }}</li>
        <li v-if="response_errors.errors.email">{{ response_errors.errors.email }}</li>
        <li v-if="response_errors.errors.password">{{ response_errors.errors.password }}</li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Lenz Media
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text-center text--primary mb-2">
            Adventure starts here 🚀
          </p>
          <p class="mb-2">
            Make your app management easy and fun!
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <validation-observer ref="observer">
            <v-form>
              <validation-provider v-slot="{ errors }" name="first name" rules="required|min:2|max:26">
                <v-text-field
                  v-model="account.first_name"
                  :error-messages="errors"
                  required
                  outlined
                  dense
                  label="First Name"
                  placeholder="John"
                  class="mb-3"
                ></v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="last name" rules="required|min:2|max:26">
                <v-text-field
                  v-model="account.last_name"
                  :error-messages="errors"
                  required
                  outlined
                  dense
                  label="Last Name"
                  placeholder="Doe"
                  class="mb-3"
                ></v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                <v-text-field
                  v-model="account.email"
                  :error-messages="errors"
                  required
                  outlined
                  dense
                  label="Email"
                  placeholder="john@example.com"
                  class="mb-3"
                ></v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="password" rules="required|min:6|max:128">
                <v-text-field
                  v-model="account.password"
                  :error-messages="errors"
                  class="mb-3"
                  required
                  outlined
                  dense
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="confirm password" rules="required|min:6|max:128">
                <v-text-field
                  v-model="account.password_confirmation"
                  :error-messages="errors"
                  class="mb-3"
                  required
                  outlined
                  dense
                  :type="isCPasswordVisible ? 'text' : 'password'"
                  label="Confirm Password"
                  placeholder="············"
                  :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </validation-provider>
              <v-row>
                <v-col cols="1">
                  <validation-provider v-slot="{ errors }" name="accept" rules="required">
                    <v-checkbox v-model="account.accept" :error-messages="errors" hide-details required class="mt-1">
                      <template #label>
                        <!-- <div class="d-flex align-center flex-wrap">
                          <span class="me-2">I agree to</span
                          ><a href="javascript:void(0)">privacy policy &amp; terms</a>
                        </div> -->
                      </template>
                    </v-checkbox>
                  </validation-provider>
                </v-col>
                <v-col>
                  <div class="py-2 d-flex align-center flex-wrap">
                    <span class="me-2">I agree to</span
                    ><a :href="`${tnc}`" target="_blank">privacy policy &amp; terms</a>
                  </div>
                </v-col>
              </v-row>

              <v-btn block color="primary" class="mt-6" @click="registration">
                Sign Up
              </v-btn>
            </v-form>
          </validation-observer>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name: 'pages-login' }">
            Sign in instead
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
// eslint-disable-next-line
import { required, min, max, email } from "vee-validate/dist/rules";
// eslint-disable-next-line
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
// eslint-disable-next-line
import util from "@/core/utils/misc";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("min", {
  ...min,
  message: "The {_field_} field must have at least {length} characters",
});

extend("max", {
  ...max,
  message: "The {_field_} field must {max} characters at most",
});

extend("email", {
  ...email,
  message: "The {_field_} must be a working email address",
});

export default {
  name: "AuthRegister",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    tnc() {
      return `${this.$MEDIA}system/docs/tnc.pdf`;
    },
  },
  // eslint-disable-next-line
  data() {
    return {
      account: {},
      isPasswordVisible: false,
      isCPasswordVisible: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      response_errors: {
        errors: {},
      },
      snackbar: false,
    };
  },

  methods: {
    // eslint-disable-next-line
    async registration() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait, we are verifying your credentials." });
        await this.$store
          .dispatch(`${this.$IYA.NAMESPACE.AUTH}/${this.$IYA.ACTION.REGISTER}`, this.account)
          .then(response => {
            this.response_errors = {};
            if (response.status === this.$IYA.HTTP.OK || response.status === this.$IYA.HTTP.CREATED) {
              this.$router.push({ name: "Verify-Email" });
            } else {
              this.response_errors = {
                message: response.data.message,
                errors: response.data.errors,
              };
              this.snackbar = true;
            }
          });
        util.isLoading();
      } else {
        console.log("Invalid");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
