var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('v-snackbar',{attrs:{"timeout":-1,"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('ul',[(_vm.response_errors.errors.first_name)?_c('li',[_vm._v(_vm._s(_vm.response_errors.errors.first_name))]):_vm._e(),(_vm.response_errors.errors.last_name)?_c('li',[_vm._v(_vm._s(_vm.response_errors.errors.last_name))]):_vm._e(),(_vm.response_errors.errors.email)?_c('li',[_vm._v(_vm._s(_vm.response_errors.errors.email))]):_vm._e(),(_vm.response_errors.errors.password)?_c('li',[_vm._v(_vm._s(_vm.response_errors.errors.password))]):_vm._e()])]),_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('v-img',{staticClass:"me-3 ",attrs:{"src":require('@/assets/images/logos/logo.png'),"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" Lenz Media ")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"text-2xl font-weight-semibold text-center text--primary mb-2"},[_vm._v(" Adventure starts here 🚀 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Make your app management easy and fun! ")])]),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('validation-provider',{attrs:{"name":"first name","rules":"required|min:2|max:26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"required":"","outlined":"","dense":"","label":"First Name","placeholder":"John"},model:{value:(_vm.account.first_name),callback:function ($$v) {_vm.$set(_vm.account, "first_name", $$v)},expression:"account.first_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"last name","rules":"required|min:2|max:26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"required":"","outlined":"","dense":"","label":"Last Name","placeholder":"Doe"},model:{value:(_vm.account.last_name),callback:function ($$v) {_vm.$set(_vm.account, "last_name", $$v)},expression:"account.last_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"required":"","outlined":"","dense":"","label":"Email","placeholder":"john@example.com"},model:{value:(_vm.account.email),callback:function ($$v) {_vm.$set(_vm.account, "email", $$v)},expression:"account.email"}})]}}])}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:6|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"required":"","outlined":"","dense":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","placeholder":"············","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.account.password),callback:function ($$v) {_vm.$set(_vm.account, "password", $$v)},expression:"account.password"}})]}}])}),_c('validation-provider',{attrs:{"name":"confirm password","rules":"required|min:6|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"required":"","outlined":"","dense":"","type":_vm.isCPasswordVisible ? 'text' : 'password',"label":"Confirm Password","placeholder":"············","append-icon":_vm.isCPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline},on:{"click:append":function($event){_vm.isCPasswordVisible = !_vm.isCPasswordVisible}},model:{value:(_vm.account.password_confirmation),callback:function ($$v) {_vm.$set(_vm.account, "password_confirmation", $$v)},expression:"account.password_confirmation"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('validation-provider',{attrs:{"name":"accept","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-1",attrs:{"error-messages":errors,"hide-details":"","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.account.accept),callback:function ($$v) {_vm.$set(_vm.account, "accept", $$v)},expression:"account.accept"}})]}}])})],1),_c('v-col',[_c('div',{staticClass:"py-2 d-flex align-center flex-wrap"},[_c('span',{staticClass:"me-2"},[_vm._v("I agree to")]),_c('a',{attrs:{"href":("" + _vm.tnc),"target":"_blank"}},[_vm._v("privacy policy & terms")])])])],1),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","color":"primary"},on:{"click":_vm.registration}},[_vm._v(" Sign Up ")])],1)],1)],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" Already have an account? ")]),_c('router-link',{attrs:{"to":{ name: 'pages-login' }}},[_vm._v(" Sign in instead ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }